
<template>
  <div>
    <form @submit.prevent="onSubmit">
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        :label="$t('user.password')"
        label-for="password"
      >
        <b-form-input
          id="password"
          v-model="playerData.password"
        />
      </b-form-group>
      <div class="text-right">
        <b-button
          class="mr-2"
          variant="light"
          @click="setData()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-overlay
          :show="isUpdatingPlayer"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
            block
            :disabled="!playerId || !playerData.password"
          >
            {{ $t('buttons.save') }}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      playerData: {
        password: '',
      },
    }
  },
  computed: {
    ...mapState({
      isUpdatingPlayer: (state) => state.player.isUpdatingPlayer,
    }),
    playerId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
  },
  methods: {
    ...mapActions(['updatePlayer']),
    setData() {
      this.playerData = {
        password: '',
      }
    },
    onSubmit() {
      this.updatePlayer({ playerId: this.playerId, data: this.playerData})
    },
  },
}
</script>
